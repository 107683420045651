<template>
  <b-row
    id="user-league-rewards"
    no-gutters
    align-h="end"
    class="drop-shadow-box h-100"
  >
    <b-col cols="12" class="text-center">
      <p class="m-0 medium text-uppercase text-white headline-1">
        {{ $t("user_leagues.rewards.headline_1") }}
      </p>
      <p class="m-0 text-white medium headline-2">
        {{ $t("user_leagues.rewards.headline_2") }}
      </p></b-col
    >
    <b-col cols="12" class="d-flex justify-content-center"
      ><div
        id="countdown-field"
        class="gradient-indent d-inline-flex justify-content-around align-items-center w-50"
      >
        <span class="text-white text-uppercase">{{
          $t("user_leagues.rewards.reset_info.countdown")
        }}</span>
        <CountdownDigital v-if="time" class="blue" :time="time" :with-time-diff="true"/></div
    ></b-col>
    <div
      v-if="shownRewards"
      class="w-100 d-inline-flex align-items-center justify-content-center"
    >
      <div v-for="reward in shownRewards" :key="reward.type" class="reward-col">
        <EarningShield
          v-if="reward.amount > 0"
          :earning="determineEarning(reward)"
          ><template v-slot:earning-amount-or-text>{{
            formatNumber(reward.amount)
          }}</template></EarningShield
        >
      </div>
    </div>
    <b-col cols="12" class="text-center text-white medium headline-2">{{
      $t("user_leagues.rewards.headline.spins_left")
    }}</b-col>
    <div class="w-100 d-inline-flex align-items-center justify-content-center">
      <div
        v-for="reward in shownRewards"
        :key="reward.id"
        class="gradient-indent reward-col text-center blue text-normal"
      >
        {{ formatNumber(determineSpins(reward)) }}x
      </div>
    </div>
    <b-col cols="12" class="text-white medium headline-2 text-center mt-3">{{
      $t("user_leagues.rewards.headline.wins_total")
    }}</b-col>
    <b-col
      cols="12"
      class="d-inline-flex justify-content-center"
      style="margin-top: -20px"
      ><div
        id="total-gold-field"
        class="gradient-indent glow d-inline-flex align-items-center justify-content-center"
      >
        <span v-if="userWins" class="blue-glow" style="font-size: 30px">{{
          userWins
        }}</span
        ><img
          draggable="false"
          alt="gold"
          src="@/assets/img/common/coin1.png"
          class="ml-3"
        /></div
    ></b-col>
  </b-row>
</template>
<script>
import CountdownDigital from "@/components/base/countdown-digital.vue";
import EarningShield from "@/components/base/earning-shield.vue";
export default {
  name: "UserLeagueRewards",
  components: { CountdownDigital, EarningShield },
  props: {
    rewards: {
      type: Object,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    userRewardStatus: {
      type: Object,
      required: true,
    },
    shownLeague: {
      type: Object,
      required: true,
    },
  },
  computed: {
    shownRewards() {
      if (!this.rewards || !this.shownLeague.id) return null;
      let rewardsArr = Object.values(this.rewards);

      return rewardsArr[this.shownLeague.id - 1].filter(
        (rew) => rew.amount > 0 && rew.counter >= 1
      );
    },
    userWins() {
      if (!this.$store.getters["auth/isLoggedIn"]) {
        return null;
      } else {
        return this.formatNumber(
          this.$store.getters["user/getUserLeagueInfos"].gold
        );
      }
    },
  },
  methods: {
    determineEarning(reward) {
      if (reward.type) {
        return reward.type;
      } else {
        return "card";
      }
    },
    determineSpins(reward) {
      let result = reward.counter - this.userRewardStatus.spins;
      if (result >= 0) {
        return result;
      } else return 0;
    },
  },
};
</script>
<style lang="scss">
#user-league-rewards {
  padding: 16px 32px 16px 32px;
  z-index: 0;

  .headline-1 {
    font-size: 26px;
    text-shadow: 0 2px 3px black;
  }
  .headline-2 {
    font-size: 21px;
    text-shadow: 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 6px rgba(77, 229, 255, 0.6), 0 0 3px rgba(0, 94, 110, 0.8);
  }
  .reward-col {
    margin-left: 5px;
    margin-right: 5px;
    width: 134px;
  }
  #countdown-field {
    height: 32px;
    border-radius: 16px;

    span {
      font-size: 16px;
    }
  }

  #total-gold-field {
    width: 312px;
    border-radius: 24px;
    height: 48px;
    img {
      max-height: 33px;
    }
  }
}
</style>
