<template>
  <div
    v-if="members.length > 0"
    id="user-league-table"
    class="container-fluid position-relative h-100"
  >
    <b-row align-h="center" align-v="center" class="custom-table-head"
      ><div id="league-pagination">
        <div
          class="triangle left"
          :class="{ disabled: isPrevButtonDisabled }"
          @click="decreasePageCount"
        ></div>
        <span
          v-if="shownLeague"
          class="row-title text-uppercase d-flex flex-column align-items-center"
        >
          {{
            $t(`user_leagues.league.${shownLeague.title}`) +
            "-" +
            $t(`user_leagues.league`)
          }}</span
        >
        <div
          class="triangle right"
          :class="{ disabled: isNextButtonDisabled }"
          @click="increasePageCount"
        ></div></div
    ></b-row>
    <b-row
      v-if="!loadingMembers"
      align-h="center"
      class="custom-table-head m-0"
    >
      <b-col cols="12" class="p-0 fit-content d-table">
        <b-table
          v-if="shownLeague && !loadingMembers"
          id="league-members"
          :fields="fields"
          :items="mappedShownMembers"
          sort-by="gold"
          sort-desc
          :sort-compare="excludePlaceholders"
          :tbody-tr-class="highlightSelf"
          :current-page="currentMembers"
          :per-page="membersPerPage"
          thead-class="head-row-text"
          responsive
          @row-clicked="openDetailsModal"
        >
          <!--      <template v-slot:table-colgroup="scope">-->
          <template v-slot:table-colgroup>
            <col key="rank" style="width: 15%" />
            <col key="username" style="width: 60%" />
            <col key="gold" style="width: 25%" />
          </template>
          <template v-slot:head()="data">{{ $t(data.label) }}</template>

          <template v-slot:cell(username)="data">
            <div v-if="data.item.user" class="text-left">
              <span>{{ shortenString(data.value, 20) }}</span>
            </div>
          </template>

          <template v-slot:cell(gold)="data">
            <div v-if="data.value" class="inline-cell">
              {{ formatNumber(data.value) }}
              <img
                draggable="false"
                alt="gold"
                src="@/assets/img/common/coin1.png"
                style="width: 16px; height: 16px"
              />
            </div>
          </template>
        </b-table>

        <tablePagination
          v-if="shownLeague && !loadingMembers"
          v-model="currentMembers"
          :per-page="membersPerPage"
          :total-rows="totalRows"
        /> </b-col
    ></b-row>
    <div v-if="loadingMembers" id="spinner-container">
      <Spinner
        size="medium"
        context="Loading..."
        classes="dead-center"
      ></Spinner>
    </div>
    <div
      v-if="shownLeague"
      id="ascend-descend"
      class="w-100 to-center-abs d-inline-flex align-items-center justify-content-center"
    >
      <template v-if="shownLeague.ascend">
        <div class="dot green"></div>
        <span class="green ml-1 mr-3">
          {{ $t("user_leagues.ascend_info", { 0: shownLeague.ascend }) }}</span
        >
      </template>
      <template v-if="shownLeague.descend">
        <div class="dot red"></div>
        <span class="red ml-1">{{
          $t("user_leagues.descend_info", { 0: shownLeague.descend })
        }}</span>
      </template>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/animation/animation-spinner";
import * as LeagueAPI from "@/API/leagues.js";
import tablePagination from "../base/pagination.vue";
import getPublicUserInfos from "@/mixins/getPublicUserInfos.js";

export default {
  name: "UserLeagueTable",
  components: { tablePagination, Spinner },
  mixins: [getPublicUserInfos],
  props: {
    allLeagues: {
      type: Array,
      required: true,
    },
    members: {
      type: Array,
      required: true,
    },
    startCount: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentPage: 0,
      totalPages: null,
      currentMembers: 1,
      membersPerPage: 10,
      loadingMembers: false,
      perPage: 1,
      otherMembers: [],
      fields: [
        {
          key: "rank",
          label: "user_leagues.table.column_head.rank",
          sortable: true,
          formatter: (value, key, item) => {
            return item.position;
          },
          sortByFormatted: true,
        },
        {
          key: "username",
          label: "user_leagues.table.column_head.user",
          sortable: true,
          formatter: (value, key, item) => {
            if (item.user) {
              return item.user.name;
            } else {
              return null;
            }
          },
          sortByFormatted: true,
        },
        {
          key: "gold",
          label: "user_leagues.table.column_head.earnings",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    isOwnLeague() {
      return (
        this.$store.getters["user/getUserLeagueInfos"].league_id ===
        this.shownLeague.id
      );
    },
    topFifty() {
      return this.$store.getters["user/getUserLeagueInfos"].position <= 50;
    },
    shownLeague() {
      return this.allLeagues[this.currentPage - 1];
    },
    shownMembers() {
      if (
        this.shownLeague.id ===
        this.$store.getters["user/getUserLeagueInfos"].league_id
      )
        return this.members;
      else {
        return this.otherMembers;
      }
    },
    mappedShownMembers() {
      let arrCopy = this.shownMembers.slice();
      if (arrCopy.length % this.membersPerPage === 0 && arrCopy.length > 0) {
        return arrCopy;
      } else {
        while (
          arrCopy.length % this.membersPerPage !== 0 ||
          arrCopy.length === 0
        ) {
          arrCopy.push({ placeholder: true });
        }
        return arrCopy;
      }
    },
    totalRows() {
      return this.mappedShownMembers.length;
    },

    showPagination() {
      if (this.totalPages <= this.perPage) {
        return false;
      } else return true;
    },
    isPrevButtonDisabled() {
      if (this.currentPage <= 1) {
        return true;
      } else {
        return false;
      }
    },
    isNextButtonDisabled() {
      if (this.currentPage >= this.totalPages) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    shownLeague() {
      this.$emit("leagueShown", this.shownLeague);

      if (this.isOwnLeague) {
        this.updateMemberPage();
      } else {
        this.currentMembers = 1;
      }
    },
  },
  mounted() {
    this.totalPages = this.allLeagues.length;
    this.currentPage = this.$store.getters["user/getUserLeagueInfos"].league_id;
    this.updateMemberPage();
  },
  methods: {
    excludePlaceholders(aRow, bRow, key, sortDesc) {
      if (aRow.placeholder && !bRow.placeholder) {
        return -1;
      } else if (!aRow.placeholder && bRow.placeholder) {
        return 1;
      } else if (aRow.placeholder && bRow.placeholder) {
        return 0;
      } else {
        return null;
      }
    },
    increasePageCount() {
      this.playSoundTab();
      this.loadingMembers = true;
      this.currentPage += 1;
      this.updateShownLeague();
    },
    decreasePageCount() {
      this.playSoundTab();
      this.loadingMembers = true;
      this.currentPage -= 1;
      this.updateShownLeague();
    },
    updateShownLeague() {
      return new Promise((resolve, reject) => {
        if (this.isOwnLeague) {
          this.loadingMembers = false;
          resolve();
        } else {
          LeagueAPI.fetchTopLeagueMembers(
            this.$store.getters["user/currentUser"].id,
            this.shownLeague.id
          ).then((res) => {
            let membersArray = res.data.data.users.data;

            this.otherMembers = membersArray;

            this.loadingMembers = false;
            resolve();
          });
        }
      });
    },
    highlightSelf(item) {
      if (
        item.user &&
        item.user.id === this.$store.getters["user/currentUser"].id
      ) {
        return "highlight";
      }
    },
    openDetailsModal(row) {
      if (row.user.deleted_at) return;
      this.fetchPublicUser(row.user.id);
    },
    updateMemberPage() {
      let userRankInShownMembers = this.shownMembers.findIndex(
        (member) => member.userId === this.$store.getters["user/currentUser"].id
      );
      let page;
      // display first page if user isn't found in data
      if (userRankInShownMembers < 0) {
        page = 1;
      } else if (userRankInShownMembers % 10 === 0) {
        page = userRankInShownMembers / 10 + 1;
      } else {
        page = Math.ceil(userRankInShownMembers / 10);
      }
      this.currentMembers = page;
    },
  },
};
</script>
<style lang="scss">
#user-league-table {
  z-index: 1;

  .fit-content {
    height: fit-content;
  }
  .table-responsive {
    overflow-x: visible;

    #league-members {
      tr {
        &.highlight {
          border: 1px solid white;
          box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
            0 0 13px rgba(77, 229, 255, 0.6), 0 0 6px rgba(0, 94, 110, 0.8);
        }
      }
    }
  }
  #league-pagination {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 16px;
    .row-title {
      font-size: 24px;
      width: 250px;
      text-align: center;
      color: #fff8b7;
      height: auto;
    }
    .triangle {
      width: 21px;
      height: 19px;
      cursor: pointer;
      &.left {
        background: url(~@/assets/img/common/arrow-left-gold.svg) center/100%
          100% no-repeat;
        -webkit-transform: translate3d(0, 0, 0);
      }
      &.right {
        background: url(~@/assets/img/common/arrow-right-gold.svg) center/100%
          100% no-repeat;
        -webkit-transform: translate3d(0, 0, 0);
      }
      &.disabled {
        visibility: hidden;
      }
      &:hover {
        filter: $hover-shadow;
      }
    }
  }
  #table-pagination {
    bottom: -70px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  #spinner-container {
    height: 550px;
  }
  #ascend-descend {
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    font-size: 15px;
    bottom: -45px;
    .dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      &.green {
        background-color: #43ff00;
      }
      &.red {
        background-color: #ff0000;
      }
    }
    span {
      &.green {
        color: #43ff00;
      }
      &.red {
        color: #ff0000;
      }
    }
  }
}
</style>
