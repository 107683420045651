<template>
  <TabbedPage>
    <template v-slot:top-tabbed-content
      ><UserLeagueInfos v-if="nextMatchday" :time="nextMatchday"
    /></template>

    <template v-slot:main-content>
      <UserLeagueContainer
        :all-leagues="allLeagues"
        :time="calculateLeagueRewardsReset"
        :user-reward-status="userRewardStatus"
        :rewards="leagueRewards"
        :error="error"
    /></template>
  </TabbedPage>
</template>

<script>
import UserLeagueInfos from "@/components/user-league/user-league-infos.vue";
import UserLeagueContainer from "@/components/user-league/user-league-container.vue";
import * as LeagueAPI from "@/API/leagues.js";
import TabbedPage from "@/views/TabbedPage.vue";

export default {
  name: "UserLeaguePage",
  components: {
    TabbedPage,
    UserLeagueInfos,
    UserLeagueContainer,
  },
  data() {
    return {
      loading: true,
      error: false,
      nextMatchday: null,
      allLeagues: [],
      userRewardStatus: {},
      leagueRewards: {},
    };
  },
  computed: {
    calculateLeagueRewardsReset() {
      let date = new Date();
      let midnight = date.setHours(24, 0, 0, 0);
      return midnight;
    },
  },
  created() {
    this.userId = this.$store.getters["user/currentUser"].id;

    Promise.all([
      this.getLeagueTime(),
      this.getAllLeagues(),
      this.getUserRewardStatus(),
      this.getLeagueRewards(),
    ])
      .then((values) => {
        this.nextMatchday = values[0];
        this.allLeagues = Object.values(values[1].data.data.league);
        this.userRewardStatus = values[2].data.data.reward;
        this.leagueRewards = values[3].data.data.rewards;
      })
      .catch((e) => {
        this.error = true;
        console.log(e);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    async getLeagueTime() {
      let response = await LeagueAPI.fetchNextMatchday();
      return new Date(response.data.data.matchday.value).getTime();
    },

    async getAllLeagues() {
      let response = await LeagueAPI.fetchAllLeagues();
      return response;
    },
    async getUserRewardStatus() {
      let response = await LeagueAPI.fetchUserRewards();
      return response;
    },
    async getLeagueRewards() {
      let response = await LeagueAPI.fetchAllLeagueRewards();
      return response;
    },
  },
};
</script>
