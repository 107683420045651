<template>
  <b-row id="user-league-infos" no-gutters class="mr-3" align-h="center">
    <b-col
      cols="3"
      lg="2"
      class="d-inline-flex justify-content-end align-items-center"
    >
      <div class="panel-black">
        <span class="white-bold">{{ $t("user_leagues.league") }}</span>
        <span class="blue-normal ml-2">
          {{ leagueTitle }}
        </span>
      </div></b-col
    ><b-col
      cols="2"
      class="d-inline-flex justify-content-center align-items-center"
    >
      <div class="panel-black">
        <span class="white-bold">{{ $t("user_leagues.your_rank") }}</span>
        <span class="blue-normal ml-2">
          {{ $store.getters["user/getUserLeagueInfos"].position }}
        </span>
      </div>
    </b-col>

    <b-col cols="4" xl="3">
      <Countdown :time="time" countdown-type="league" :with-time-diff="true"/>
    </b-col>
  </b-row>
</template>
<script>
import Countdown from "../base/countdown.vue";
export default {
  name: "UserLeagueInfos",

  components: { Countdown },
  props: {
    time: {
      type: Number,
      required: true,
    },
  },
  computed: {
    leagueTitle() {
      if (!this.$store.getters["auth/isLoggedIn"]) return "";
      return this.$t(
        `user_leagues.league.${this.$store.getters["user/getUserLeagueInfos"].league.title}`
      );
    },
  },
};
</script>
<style lang="scss">
#user-league-infos {
  font-size: 16px;

  .white-bold {
    font-family: Ubuntu-Bold;
    text-transform: uppercase;
    color: white;
  }
  .blue-normal {
    color: $light-blue;
  }

  .panel-black {
    background-color: black;
    height: 2rem;
    box-shadow: 0 3px 0 #44a5b7;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 0.5rem;
    min-width: 8rem;
  }
}
</style>
