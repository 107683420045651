<template>
  <b-col id="user-league-container" cols="12">
    <b-row id="user-league-content-row">
      <b-col id="table-col" class="user-league-col pt-1 gold-border-box"
        ><UserLeagueTable
          v-if="allLeagues && members.length > 0 && !error"
          :all-leagues="allLeagues"
          :members="members"
          @leagueShown="updateShownLeague" /><ErrorPlaceholder
          v-else-if="
            (members.length <= 0 && error) || (allLeagues.length <= 0 && error)
          "
          class="spotlight-bg h-100"
      /></b-col>
      <b-col id="reward-col" class="user-league-col"
        ><UserLeagueRewards
          v-if="members && rewards && !error"
          :rewards="rewards"
          :time="time"
          :user-reward-status="userRewardStatus"
          :shown-league="shownLeague" /><ErrorPlaceholder v-else /></b-col
    ></b-row>
  </b-col>
</template>

<script>
import UserLeagueRewards from "./user-league-rewards.vue";
import UserLeagueTable from "./user-league-table.vue";
import * as LeagueAPI from "@/API/leagues.js";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";

export default {
  name: "UserLeagueContainer",
  components: {
    UserLeagueRewards,
    UserLeagueTable,
    ErrorPlaceholder,
  },
  props: {
    allLeagues: {
      type: Array,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
    userRewardStatus: {
      type: Object,
      required: true,
    },
    rewards: {
      type: Object,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      league: {},
      members: {},
      shownLeague: {},
    };
  },
  mounted() {
    this.$store
      .dispatch("user/fetchUserLeagueInfos")
      .then((res) => {
        let page;
        let position = res.data.data.league.position;
        if (position % 50 === 0) {
          page = Math.floor(position / 50);
        } else {
          page = Math.floor(position / 50 + 1);
        }
        Math.floor(position / 50 + 1);
        LeagueAPI.fetchLeagueMembers(
          this.$store.getters["user/currentUser"].id,
          this.$store.getters["user/getUserLeagueInfos"].league_id,
          page
        )
          .then((res) => {
            this.members = res.data.data.users.data;
          })
          .catch((e) => {
            this.members = [];
            console.log(e);
          })
          .finally(() => {
            this.loading = false;
            this.$store.commit("setIsPageLoading", false);
          });
      })
      .catch((e) => console.log(e));
  },
  methods: {
    updateShownLeague(league) {
      this.shownLeague = league;
    },
  },
};
</script>

<style lang="scss">
#user-league-container {
  padding: 0;
  #user-league-content-row {
    justify-content: space-around;
    align-items: center;
  }
  .user-league-col {
    height: 600px;
  }
  #reward-col {
    max-width: 600px;
  }
  #table-col {
    max-width: 600px;
    min-width: 450px;
    @media (max-width: 1243px) {
      margin-bottom: 20px;
    }
  }
}
</style>
